import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/App.css';
import ReactInputMask from 'react-input-mask';
import ConfirmationCnpj from './ConfirmationCnpj';
import { CSSTransition } from 'react-transition-group';

function CteEdit() {
    const { setAlert, axiosConfigured, auth, hasRequiredRoleDfe } = useContext(AuthContext);
    const havePermission = hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_USER') && !auth.readOnlyMode;
    const [formData, setFormData] = useState({
        /*supplierType: 'PESSOA_JURIDICA',
        name: '',
        legalName: '',
        cnpj: '',
        stateRegistration: '',
        cityRegistration: '',
        phone: '',
        cellPhone: '',
        email: '',
        website: '',
        active: true,
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        zipCode: '',
        city: null,
        simplesNacional: false*/
    });
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [selectedCity, setSelectedCity] = useState(null);
    const [showConfirmCnpj, setShowConfirmCnpj] = useState(false);
    const [cnpjToConfirm, setCnpjToConfirm] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { cte: initialCte, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    //const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [cte, setCte] = useState(initialCte || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);
    const lastValidatedCnpj = useRef('');

    const loadCte = useCallback(async () => {
        try {
            const response = await axios.get(`/api/ctes/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar conhecimento de transporte:', error);
            if (error.response && error.response.status === 404) {
                navigate('/ctes', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadCte();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setFormData((prevFormData) => ({
                ...prevFormData/*,
                supplierType: 'PESSOA_JURIDICA'*/
            }));
            setShowForm(true);
        }
    }, [axiosConfigured, id, loadCte]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (cte) => {
        setCte(cte);
        setFormData({
            /*supplierType: supplier.supplierType || 'PESSOA_JURIDICA',
            name: supplier.name || '',
            legalName: supplier.legalName || '',
            cnpj: supplier.cnpj || '',
            cpf: supplier.cpf || '',
            stateRegistration: supplier.stateRegistration || '',
            cityRegistration: supplier.cityRegistration || '',
            phone: supplier.phone || '',
            cellPhone: supplier.cellPhone || '',
            email: supplier.email || '',
            website: supplier.website || '',
            active: supplier.active || 'true',
            street: supplier.street || '',
            number: supplier.number || '',
            complement: supplier.complement || '',
            neighborhood: supplier.neighborhood || '',
            zipCode: supplier.zipCode || '',
            city: supplier.city || null,
            simplesNacional: supplier.isSimples? supplier.isSimples: supplier.simplesNacional || 'false'*/
        });
        /*setSelectedCity(supplier.city ? {
            label: `${supplier.city.name} / ${supplier.city.uf}`,
            value: supplier.city.id || '',
            name: supplier.city.name || '',
            province: supplier.city.uf || ''
        } : null);*/
    };

    const handleCnpj = useCallback(async (cnpj) => {
        try {
            const response = await axios.get(`/api/cnpj/${cnpj}`);
            if (response.data) {
                //setAlert({ type: 'info', message: 'Dados do CNPJ sugeridos no formulário.' });
                confirmCnpj(response.data);
                //setIsFormChanged(true);
            } else {
                response.data.cnpj = cnpj;
            }
            if (id !== "new") {
                response.data.id = id;
            }
            //initializeForm(response.data);
        } catch (error) {
            //console.info("Não foi possível consultar o CNPJ");
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let parsedValue = value;

        /*let cnpj = '';
        let parsedValue = value;

        if (name === 'stateRegistration' && value.length > 14) {
            return;
        }

        if (name === 'simplesNacional' || name === 'endConsumer' || name === 'active') {
            parsedValue = value === 'true';
        }*/
    
        setFormData((prevData) => {
            /*const isChanged = prevData[name] !== parsedValue;

            if (name === 'supplierType' && isChanged) {
                //setIsFormChanged(true);
                return {
                    ...prevData,
                    supplierType: parsedValue,
                    cnpj: '',
                    cpf: '',
                    legalName: '',
                    name: ''
                };
            }
    
            if (name === "cnpj" && isChanged) {
                cnpj = removeFormatting(value);
                if (cnpj.length === 14 && cnpj !== lastValidatedCnpj.current) {
                    lastValidatedCnpj.current = cnpj;
                    handleCnpj(cnpj);
                }
            }*/
    
            /*if (isChanged) {
                setIsFormChanged(true);
            }*/

            return { ...prevData, [name]: parsedValue };
        });
    };

    const confirmCnpj = (cnpjToConfirm) => {
        setCnpjToConfirm(cnpjToConfirm);
        setShowConfirmCnpj(true);
    };

    const handleSelectCnpj = async (cte) => {
        initializeForm(cte);
        setShowConfirmCnpj(false);
        //setIsFormChanged(true);

    };

    const handleCityChange = (selectedOption) => {
        const selectedCity = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.name,
            uf: selectedOption.province
        } : null;
        
        setSelectedCity(selectedOption);
        setFormData((prevData) => {
            return { ...prevData, city: selectedCity };
        });
    };

    const validateRequiredFields = () => {
        const errors = {};

        /*if (formData.supplierType === 'PESSOA_JURIDICA') {
            if (!formData.cnpj) errors.cnpj = true;
        } else {
            if (!formData.cpf) errors.cpf = true;
        }
        
        if (!formData.legalName) errors.legalName = true;
        if (!selectedCity) errors.city = true;

        const countErros = Object.keys(errors).length;

        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }*/

        //console.log(errors);
        
        return errors;
    };

    function removeFormatting(value) {
        if (!value) {
            return '';
        }
        return value.replace(/[^\d]/g, '');
    }

    const handleSave = async () => {
        setIsSaving(true);

        try {
            const errors = validateRequiredFields();
    
            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }
        
            setFormErrors({});

            const dataToSend = {
                ...formData,
                /*city: selectedCity ? {
                    id: selectedCity.value,
                    name: selectedCity.name,
                    uf: selectedCity.province
                } : null,
                simplesNacional: formData.simplesNacional === true || formData.simplesNacional === 'true'*/
            };

            /*dataToSend.cnpj = removeFormatting(dataToSend.cnpj);
            dataToSend.cpf = removeFormatting(dataToSend.cpf);
            dataToSend.zipCode = removeFormatting(dataToSend.zipCode);
            dataToSend.phone = removeFormatting(dataToSend.phone);
            dataToSend.cellPhone = removeFormatting(dataToSend.cellPhone);*/
    
            if (cte.id) {
                await axios.put(`/api/ctes/${cte.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Conhecimento de transporte editado com sucesso.' });
            } else {
                await axios.post('/api/ctes', dataToSend);
                setAlert({ type: 'success', message: 'Conhecimento de transporte adicionado com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/ctes', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            //setIsFormChanged(true);
            //console.error('Erro ao salvar fornecedor', error);
        } finally {
            setTimeout(() => {
                setIsSaving(false);
            }, 1000);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/ctes', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setShowButtons(true)}>
            <div>
                <div className="form-limited-width mt-3">
                    {loading ? (
                        <div className="spinner-container">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : showForm ? (
                        <div>
                            <h3 className="mb-3">{cte.id ? (havePermission? 'Editar CT-e': 'CT-e') : 'Novo CT-e'}</h3>
                            <form>
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Pré preenchimento</h5>
                                    <div className="mb-3">
                                        <label className="form-label">Chave de acesso: <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="accessKey"
                                            value={formData.accessKey || ''}
                                            onChange={handleInputChange}
                                            maxLength={44}
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Informações básicas</h5>
                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <label className="form-label">Remetente</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="legalName"
                                                value={formData.sender.legalName || ''}
                                                onChange={handleInputChange}
                                                maxLength={60}
                                                autoComplete="off"
                                                disabled={!havePermission}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <label className="form-label">CNPJ</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="number"
                                                value={formData.sender.cnpj || ''}
                                                onChange={handleInputChange}
                                                maxLength={14}
                                                autoComplete="off"
                                                disabled={!havePermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Inscrição estadual</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="stateRegistration"
                                            value={formData.sender.stateRegistration || ''}
                                            onChange={handleInputChange}
                                            max="99999999999999"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <label className="form-label">Logradouro</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="street"
                                                value={formData.sender.street || ''}
                                                onChange={handleInputChange}
                                                maxLength={100}
                                                autoComplete="off"
                                                disabled={!havePermission}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <label className="form-label">Número</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="number"
                                                value={formData.sender.number || ''}
                                                onChange={handleInputChange}
                                                maxLength={10}
                                                autoComplete="off"
                                                disabled={!havePermission}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-md-8">
                                            <label className="form-label">Bairro</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="neighborhood"
                                                value={formData.sender.neighborhood || ''}
                                                onChange={handleInputChange}
                                                maxLength={60}
                                                autoComplete="off"
                                                disabled={!havePermission}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label mt-3 mt-md-0">CEP</label>
                                            <ReactInputMask mask="99999-999" value={formData.sender.zipCode || ''} onChange={handleInputChange} autoComplete="off" disabled={!havePermission}>
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="zipCode" disabled={!havePermission} />}
                                            </ReactInputMask>
                                        </div>
                                    </div>

                                    <div className="row mb-0">
                                        <div className={formErrors.city ? 'col-md-8 has-error' : 'col-md-8'}>
                                            <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                            <AsyncSelect2
                                                url="/api/cities"
                                                value={selectedCity}
                                                onChange={handleCityChange}
                                                valueField="ibgeCode"
                                                labelField="name"
                                                placeholder="Selecione a cidade"
                                                initialOptions={selectedCity ? [selectedCity] : []}
                                                labelFormatter={(item) => `${item.name} / ${item.province.uf}`}
                                                isDisabled={!havePermission}
                                                showClear
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3 mt-md-0">
                                            <label className="form-label">Complemento</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="complement"
                                                value={formData.sender.complement}
                                                onChange={handleInputChange}
                                                maxLength={60}
                                                autoComplete="off"
                                                disabled={!havePermission}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : null}

                    <ConfirmationCnpj
                        show={showConfirmCnpj}
                        onHide={() => setShowConfirmCnpj(false)}
                        onConfirm={() => handleSelectCnpj(cnpjToConfirm)}
                        cnpjData={cnpjToConfirm}
                    />
                </div>

                <div className={`button-bar ${showButtons && showForm? 'show' : ''}`} title={!havePermission ? "Sem permissão": ""}>
                    <div className="button-bar-container">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleSave}
                            disabled={!havePermission || isSaving}>
                            {isSaving && (<FontAwesomeIcon icon={faSpinner} spin className='me-2' />)}<FontAwesomeIcon icon={faSave} /> Salvar
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default CteEdit;
