import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext, useNfeWebSocket } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faPencil, faTrash, faInfoCircle, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import ConfirmationModal from './ConfirmationModal';
//import CancelInvoiceConfirmation from './CancelInvoiceConfirmation';
import AlertModal from './AlertModal';
import { CSSTransition } from 'react-transition-group';
import '../assets/styles/InvoiceStatus.css';

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Limpa o listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
}

function CteList() {
    const {auth, setAlert, axiosConfigured, hasRequiredRoleDfe } = useContext(AuthContext);
    const [ctes, setCtes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState(2);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
    const [showInfoErrorModal, setShowInfoErrorModal] = useState(null);
    const [cteToDelete, setCteToDelete] = useState(null);
    const [cteToCancel, setCteToCancel] = useState(null);
    const hasFetched = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [inProp, setInProp] = useState(false);
    const [selectedCtes, setSelectedCtes] = useState([]);
    //const nfeData = useNfeWebSocket();
    const allowedStatuses = ["NAO_ENVIADO", "PROCESSADO_COM_ERRO"];
    const havePermission = hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_USER') && !auth.readOnlyMode;
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
    
    const formatStatus = (status) => {
        const statusEnumMap = {
            "NAO_ENVIADO": { label: "Não Enviado", className: "status-nao-enviada" },
            "CTE_AUTORIZADO": { label: "CTe Autorizado", className: "status-autorizada" },
            "PROCESSADO_COM_ERRO": { label: "Processado com Erro", className: "status-com-erro" },
            "CANCELADO": { label: "Cancelado", className: "status-cancelada" }
        };
    
        return statusEnumMap[status] || { label: "STATUS DESCONHECIDO", className: "status-desconhecido" };
    };

    const formatStatusMobile = (status) => {
        const statusEnumMap = {
            "NAO_ENVIADO": { label: "N", className: "status-nao-enviada" },
            "CTE_AUTORIZADO": { label: "A", className: "status-autorizada" },
            "PROCESSADO_COM_ERRO": { label: "E", className: "status-com-erro" },
            "CANCELADO": { label: "C", className: "status-cancelada" }
        };
    
        return statusEnumMap[status] || { label: "STATUS DESCONHECIDO", className: "status-desconhecido" };
    };

    const fetchCtes = useCallback(async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/ctes', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const cteData = response.data.content || [];
            setCtes(cteData);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar conhecimentos de transporte', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            const state = location.state;
            if (state) {
                setSearchTerm(state.searchTerm || '');
                setPage(state.page || 0);
                setRows(state.rows || 10);
                setSortField(state.sortField || 'id');
                setSortOrder(state.sortOrder || 2);
            }
            fetchCtes(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            hasFetched.current = true;
        }
    }, [fetchCtes, axiosConfigured, location.state, page, rows, searchTerm, sortField, sortOrder]);

    /*useEffect(() => {
        if (nfeData) {
            setInvoices((prevInvoices) => 
                prevInvoices.map((invoice) => 
                    invoice.id === nfeData.id
                        ? {
                            ...invoice,
                            accessKey: nfeData.accessKey,
                            status: nfeData.status,
                            dhIssue: nfeData.dhIssue,
                            docNumber: nfeData.docNumber,
                            invoiceProtocol: nfeData.invoiceProtocol,
                            invoiceCancelProtocol: nfeData.invoiceCancelProtocol,
                        }
                        : invoice
                )
            );
        }
    }, [nfeData]);*/

    const handleShowInfoErrorModal = (invoice) => {
        const hasProtocol = invoice.invoiceProtocol? true: false;
        const hasCancelProtocol = invoice.invoiceCancelProtocol? true: false;

        const protocol = {
            status: invoice.status,
            statusCode: hasProtocol? invoice.invoiceProtocol.statusCode: '',
            statusReason: hasProtocol? invoice.invoiceProtocol.statusReason: '',
            messageCode: hasProtocol? invoice.invoiceProtocol.messageCode: '',
            messageDescription: hasProtocol? invoice.invoiceProtocol.messageDescription: '',
            cancelCode: hasCancelProtocol? invoice.invoiceCancelProtocol.statusCode: '',
            cancelMessage: hasCancelProtocol? invoice.invoiceCancelProtocol.statusReason: ''
        }

        setShowInfoErrorModal(protocol);
    };

    const handleSearch = () => {
        setSelectedCtes([]);
        fetchCtes(page, rows, sortField, sortOrder, searchTerm);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 1 ? -1 : 1;
        setSortField(field);
        setSortOrder(order);
        fetchCtes(page, rows, field, order, searchTerm);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);
        fetchCtes(event.page, event.rows, sortField, sortOrder, searchTerm);
    };

    const toggleSelectCte = (cteId) => {
        setSelectedCtes((prevSelected) =>
            prevSelected.includes(cteId)
                ? prevSelected.filter((id) => id !== cteId)
                : [...prevSelected, cteId]
        );
    };

    /*const handleDownloadXML = async (invoice) => {
        try {
            const response = await axios.get(`/api/invoices/outgoing/${invoice.accessKey}/xml`, {
                responseType: 'blob',
            });

            const legalName = normalizeFileName(invoice.customer.legalName);
            const fileName = `NFE_${invoice.docNumber}_XML_${legalName}.xml`;
    
            // Cria um link temporário para baixar o arquivo
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    };*/

    /*const normalizeFileName = (name) => {
        return name
            .normalize("NFD") // Normaliza para decompor os caracteres com acentos
            .replace(/[\u0300-\u036f]/g, "") // Remove os acentos
            .replace(/[^a-zA-Z0-9]/g, "_") // Substitui caracteres especiais e espaços por "_"
            .toUpperCase(); // Converte para letras minúsculas (opcional)
    };*/

    /*const handleDanfe = async (invoice) => {
        try {
            setAlert({ type: 'info', message: 'Gerando DANFE...', timeout: 5000 });
            const response = await axios.get(`/api/invoices/outgoing/${invoice.accessKey}/danfe`, {
                responseType: 'blob',
            });

            const legalName = normalizeFileName(invoice.customer.legalName);
            const fileName = `NFE_${invoice.docNumber}_DANFE_${legalName}.pdf`;

            // Cria um link temporário para baixar o arquivo
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao baixar o danfe:', error);
        }
    }*/

    const handleEditCte = async (cte) => {
        try {
            setInProp(false);
            setTimeout(() => {
                navigate(`/ctes/${cte.id}`, {
                    state: { cte, searchTerm, page, rows, sortField, sortOrder },
                });
            }, 300);
        } catch (error) {
            console.error('Erro ao buscar conhecimento de transporte atualizado', error);
            setAlert({ type: 'danger', message: 'Erro ao carregar o conhecimento de transporte atualizado' });
        }
    };

    const handleDeleteCte = async () => {
        try {
            await axios.delete(`/api/ctes/${cteToDelete.id}`);
            setAlert({ type: 'success', message: 'Conhecimento de transporte excluído com sucesso' });
            setCteToDelete('');
            setShowConfirmModal(false);
            fetchCtes(page, rows, sortField, sortOrder, searchTerm);
        } catch (error) {
            console.error('Erro ao excluir conhecimento de transporte', error);
            setShowConfirmModal(false);
        }
    };

    const confirmDeleteCte = (cte) => {
        setCteToDelete(cte);
        setShowConfirmModal(true);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div className='container'>
                <h3 className="mb-3 mt-3">Conhecimentos de Transporte</h3>

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div title={!havePermission? "Sem permissão": ""} style={{ display: "inline-block" }}>
                        <button className="btn btn-primary" onClick={() => navigate('/ctes/new', { state: { searchTerm, page, rows, sortField, sortOrder } })} disabled={!havePermission}>
                            <span className="d-none d-md-inline">Novo CT-e</span>
                            <span className="d-inline d-md-none">Novo</span>
                        </button>
                    </div>

                    <div className="input-group search-size" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                {selectedCtes.length > 0 && (
                    <div className="d-flex justify-content-end mb-3">
                        <button
                            className="btn btn-success"
                            //onClick={handleTransmitSelectedCtes}
                        >
                            Transmitir Selecionados
                        </button>
                    </div>
                )}

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : ctes.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className='d-none d-md-table-cell'>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    const allowedCtes = ctes
                                                        .filter((c) => allowedStatuses.includes(c.status))
                                                        .map((c) => c.id);
                                                    setSelectedCtes(allowedCtes);
                                                } else {
                                                    setSelectedCtes([]);
                                                }
                                            }}
                                            checked={
                                                ctes.every((c) => 
                                                    selectedCtes.includes(c.id) || !allowedStatuses.includes(c.status)
                                                ) &&
                                                ctes.some((c) => allowedStatuses.includes(c.status))
                                            }
                                            disabled={!havePermission}
                                        />
                                    </th>
                                    <th onClick={() => handleSort('docNumber')} className='sortable-column' style={{width: isMobile? "80px": ""}}>Número {getSortIcon('docNumber')}</th>
                                    <th onClick={() => handleSort('dhIssue')} className='sortable-column d-none d-lg-table-cell'>Data/Hora da Emissão {getSortIcon('dhIssue')}</th>
                                    <th onClick={() => handleSort('recipient.legalName')} className='sortable-column'>Cliente {getSortIcon('recipient.legalName')}</th>
                                    <th onClick={() => handleSort('status')} className='sortable-column d-none d-lg-table-cell text-end me-20'>Status {getSortIcon('status')}</th>
                                    <th className='text-end'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                            {ctes.map((cte) => (
                                    <tr key={cte.id}>
                                        <td className='d-none d-md-table-cell'>
                                            <input
                                                type="checkbox"
                                                checked={selectedCtes.includes(cte.id)}
                                                onChange={() => toggleSelectCte(cte.id)}
                                                disabled={!havePermission || (cte.status !== 'NAO_ENVIADO' && cte.status !== 'PROCESSADO_COM_ERRO')}
                                            />
                                        </td>
                                        <td>{cte.docNumber ? cte.docNumber.toString().padStart(8, '0') : 'N/A'}</td>
                                        <td className="d-none d-lg-table-cell">{cte.dhIssue ? `${new Date(cte.dhIssue).toLocaleDateString('pt-BR')} ${new Date(cte.dhIssue).toLocaleTimeString('pt-BR')}` : 'N/A'}</td>
                                        <td className="ellipsis">{cte.recipient.legalName.toUpperCase()}</td>
                                        <td className="d-none d-lg-table-cell text-end me-20">
                                            <span className={formatStatus(cte.status).className} onClick={() => handleShowInfoErrorModal(cte)}>
                                                {formatStatus(cte.status).label}
                                                {!cte.cteCancelProtocol?.id && cte.cteProtocol && cte.status === 'PROCESSADO_COM_ERRO' && (
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        className="ms-2 text-warning"
                                                        title="Ver detalhes"
                                                        onClick={() => handleShowInfoErrorModal(cte)}
                                                    />
                                                )}
                                                {cte.cteCancelProtocol && cte.cteCancelProtocol.id && cte.status !== 'CANCELADO' && (
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        className="ms-2 text-warning"
                                                        title="Ver detalhes"
                                                        onClick={() => handleShowInfoErrorModal(cte)}
                                                    />
                                                )}
                                            </span>
                                        </td>
                                        <td className='text-end' style={{width: isMobile? "74px": "130px", whiteSpace: 'nowrap'}}>
                                            {isMobile && (
                                                    <span className={formatStatusMobile(cte.status).className} onClick={() => handleShowInfoErrorModal(cte)} style={{marginRight: '10px'}}>
                                                        {formatStatusMobile(cte.status).label}
                                                    </span>
                                            )}
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title={havePermission? "Editar": "Visualizar"} onClick={() => handleEditCte(cte)} style={{display: isMobile? 'none': ''}}>
                                                <FontAwesomeIcon icon={havePermission? faPencil: faEye} />
                                            </button>
                                            <div title={!havePermission? "Sem permissão": ""} style={{ display: "inline-block" }}>
                                                <button className="btn btn-sm border-secondary custom-btn me-2" title="Excluir" onClick={() => confirmDeleteCte(cte)} disabled={!havePermission} style={{display: isMobile? 'none': ''}}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                            <div className="dropdown d-inline">
                                                {havePermission && (
                                                    <>
                                                        <button className="btn btn-sm border-secondary custom-btn dropdown-toggle no-dropdown-icon" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li style={{display: isMobile? '': 'none'}}>
                                                                <button className="dropdown-item" onClick={() => handleEditCte(cte)}>{havePermission && cte.status !== 'CTE_AUTORIZADO' && cte.status !== 'CANCELADO'? 'Editar': 'Visualizar'}</button>
                                                            </li>
                                                            {/*<li>
                                                                <button className="dropdown-item" onClick={() => confirmCancelCte(cte)} disabled={cte.status !== 'CTE_AUTORIZADO' || !havePermission}>Cancelar CT-e</button>
                                                            </li>*/}
                                                            {/*<li>
                                                                <button className="dropdown-item" onClick={() => handleTransmitCte(cte)} disabled={(cte.status !== 'NAO_ENVIADO' && cte.status !== 'PROCESSADO_COM_ERRO') || !havePermission}>Transmitir</button>
                                                            </li>*/}
                                                            {/*<li>
                                                                <button className="dropdown-item" onClick={() => handleDownloadXML(cte)} disabled={(cte.status !== 'CTE_AUTORIZADO' && cte.status !== 'CANCELADO') || !havePermission}>Download XML</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => handleDanfe(cte)} disabled={(cte.status !== 'CTE_AUTORIZADO' && cte.status !== 'CANCELADO') || !havePermission}>DACTE</button>
                                                            </li>*/}
                                                            <li style={{display: isMobile? '': 'none'}}>
                                                                <button className="dropdown-item" onClick={() => confirmDeleteCte(cte)} disabled={!havePermission}>Excluir</button>
                                                            </li>
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                        <Paginator
                            first={page * rows}
                            rows={rows}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                            pageLinkSize={isMobile ? 2 : 5}
                            {...(!isMobile && { rowsPerPageOptions: [10, 20, 50] })}
                        />
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
                    </div>
                )}

                <ConfirmationModal
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                    onConfirm={handleDeleteCte}
                    title="Confirmar Exclusão"
                    message={`Tem certeza que deseja excluir o conhecimento de transporte?`}
                />

                {/*<CancelCteConfirmation
                    show={showCancelConfirmModal}
                    onHide={() => setShowCancelConfirmModal(false)}
                    onConfirm={handleCancelCte}
                />*/}

                <AlertModal
                    show={!!showInfoErrorModal}
                    onHide={() => setShowInfoErrorModal(null)}
                    title="Informações"
                    message={
                        showInfoErrorModal && (
                            <>
                                <p><strong>Status atual:</strong> {formatStatus(showInfoErrorModal.status).label}</p>
                                {(showInfoErrorModal.status !== 'NAO_ENVIADO') && (
                                    <>
                                        <br/>
                                        <p><strong><u>Emissão</u></strong></p>
                                        <p><strong>Código do Status:</strong> {showInfoErrorModal.statusCode}</p>
                                        <p><strong>Motivo:</strong> {showInfoErrorModal.statusReason}</p>
                                        {showInfoErrorModal.messageCode && (
                                            <p><strong>Código da Mensagem:</strong> {showInfoErrorModal.messageCode}</p>
                                        )}
                                        {showInfoErrorModal.messageDescription && (
                                            <p><strong>Descrição da Mensagem:</strong> {showInfoErrorModal.messageDescription}</p>
                                        )}
                                        {showInfoErrorModal.messageCode && (
                                            <p><strong>Código da Mensagem:</strong> {showInfoErrorModal.messageCode}</p>
                                        )}
                                        {showInfoErrorModal.cancelCode && (
                                            <>
                                                <br/>
                                                <p><strong><u>{showInfoErrorModal.status === 'CANCELADO'? 'Cancelamento': 'Tentativa de cancelamento'}</u></strong></p>
                                                <p><strong>Código:</strong> {showInfoErrorModal.cancelCode}</p>
                                                <p><strong>Mensagem:</strong> {showInfoErrorModal.cancelMessage}</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )
                    }
                />
            </div>
        </CSSTransition>
    );
}

export default CteList;
